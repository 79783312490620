import React, { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { listUsers } from 'sigap/services/users'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { AdminContainer } from '../styles'
import { UserModal } from '../UserModal/UserModal'

const columns: TableColumn<IUser>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'NOME',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'EMAIL',
    selector: (row) => row.email,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'PERFIL',
    selector: (row) => row.perfil,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'ÁREA',
    selector: (row) => row.area,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const UserList: React.FC = () => {
  const [showUserModal, setShowUserModal] = useState<boolean>(false)
  const [userModalData, setUserModalData] = useState<IUser>(null)

  const { data: users, loading: usersLoading } = useRequest(listUsers)

  const handleShowModal = (user?: IUser): void => {
    setShowUserModal(true)
    if (user != null) {
      setUserModalData(user)
    }
  }

  return (
    <>
      <AdminContainer>
        <div className="button-row-container">
          <GenericButton onClick={() => handleShowModal()} colorScheme="green">
            Novo Usuário
            <BsPlusLg />
          </GenericButton>
        </div>
        <div className="admin-body">
          <div className="table-title contratos">
            <div className="table-title-header">Usuários</div>
            <div className="table-container">
              {usersLoading ? (
                <div className="loading-container">
                  <CircularProgress isIndeterminate color="#001B37" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={users}
                  dense
                  onRowClicked={(user) => handleShowModal(user)}
                  pointerOnHover
                  highlightOnHover
                  striped
                  noDataComponent={<NoDataComponent dataType="usuários" />}
                />
              )}
            </div>
          </div>
        </div>
      </AdminContainer>
      <UserModal
        setShowUserModal={setShowUserModal}
        setUserModalData={setUserModalData}
        showUserModal={showUserModal}
        userModalData={userModalData}
      />
    </>
  )
}
