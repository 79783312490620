import React, { useState, useMemo, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelectCreatable from 'react-select/creatable'

import { CardHeader, Input, useToast } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { insertClassificacao, listClassificacao } from 'sigap/services/classificacao'
import { editDemanda, getDemanda, insertDemanda } from 'sigap/services/demandas'
import { getDocumentosByDemanda } from 'sigap/services/documentos'
import { listPublicoAlvo } from 'sigap/services/publicoAlvo'
import { listSubsecretarias } from 'sigap/services/subsecretaria'
import { ITarefasDto, getTarefasByDemanda, insertTarefasOnDemanda } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { ITarefa } from 'sigap/types/tarefas'
import { documentoColumns } from 'sigap/utils/constants/columns'
import { DEMANDA_CREATE_TOAST_MESSAGE, DEMANDA_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericTable } from 'sigap/utils/generic'
import { LargeCardInfo } from 'sigap/utils/generic/CardInfo/CardInfo'
import { CommentChat } from 'sigap/utils/generic/CommentChat/CommentChat'
import { DuracaoPicker } from 'sigap/utils/generic/DuracaoPicker/DuracaoPicker'
import { SmallFormCard } from 'sigap/utils/generic/FormCardStyles/FormCardStyles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { returnNumberArrayFromIBasicInfoArray } from 'sigap/utils/methods/returnNumberArrayFromIBasicInfoArray'
import { returnNumberOptionArrayFromBasicInfoArray } from 'sigap/utils/methods/returnNumberOptionArrayFromBasicInfoArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'
import { returnOptionFromBasicInfo } from 'sigap/utils/methods/returnOptionFromBasicInfo'

import { ModalNovaTarefa } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/ModalNovaTarefa/ModalNovaTarefa'
import { ModalNovoDocumento } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/ModalNovoDocumento/ModalNovoDocumento'
import { TarefaList } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/TarefaList/TarefaList'
import { DemandEditorContainer } from './styles'

interface IFormData {
  nome: string
  objetivo: string
  subsecretaria: Option<number>
  justificativa: string
  classificacao: Option<number>[]
  publico_alvo: Option<number>[]
  demandante: Option<number>
  responsavel: Option<number>[]
  equipe: Option<number>[]
}

export const DemandEditor: React.FC = () => {
  const params = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])
  const [createClassificacaoLoading, setCreateClassificacaoLoading] = useState<boolean>(false)
  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean>()
  const [tarefaModalData, setTarefaModalData] = useState<ITarefa>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [tempTarefas, setTempTarefas] = useState<ITarefa[]>([])
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [showNovoDocumentoModal, setShowNovoDocumentoModal] = useState<boolean>(false)
  const [documentoModalData, setDocumentoModalData] = useState<IDocumentos>()
  const [tempDocumentos, setTempDocumentos] = useState<IDocumentos[]>()

  const { data: subsecretarias } = useRequest(listSubsecretarias)

  const { data: publicoAlvo } = useRequest(listPublicoAlvo)
  const { data: classificacao } = useRequest(listClassificacao)
  const { data: users } = useRequest(listUsers)

  const {
    data: demanda,
    refreshAsync: refreshDemanda,
    loading: demandaLoading
  } = useRequest(getDemanda, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const {
    data: tarefas,
    refreshAsync: refreshTarefas,
    loading: tarefasLoading
  } = useRequest(getTarefasByDemanda, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const {
    data: documentos,
    refreshAsync: refreshDocumentos,
    loading: documentosLoading
  } = useRequest(getDocumentosByDemanda, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const currentFormObject = watch()

  const isFormComplete = useMemo(
    () =>
      currentFormObject.nome?.length > 0 &&
      currentFormObject.objetivo?.length > 0 &&
      currentFormObject.justificativa?.length > 0 &&
      currentFormObject.classificacao != null &&
      currentFormObject.subsecretaria != null &&
      currentFormObject.publico_alvo != null &&
      currentFormObject.demandante != null &&
      currentFormObject.responsavel != null &&
      currentFormObject.equipe != null &&
      tempTarefas.length > 0,
    [currentFormObject, tempTarefas]
  )

  const handleDateChange = (dates: Date[]): void => {
    setSelectedDates(dates)
  }

  const handleShowNovoDocumentoModal = (): void => {
    setShowNovoDocumentoModal(true)
  }

  const handleSetIsEditMode = (): void => {
    setIsEditMode(true)
  }
  const handleCancelEdit = (): void => {
    refreshDemanda()
    setIsEditMode(false)
  }

  const normalizeDemandaData = (formObject: IFormData): IDemanda => {
    const equipeIdArray = returnNumberValueArrayFromMultiSelect(formObject.equipe)
    const publicoAlvoIdArray = returnNumberValueArrayFromMultiSelect(formObject.publico_alvo)

    const responsavelIdArray = returnNumberValueArrayFromMultiSelect(formObject.responsavel)

    const classificacaoIdArray = returnNumberValueArrayFromMultiSelect(formObject.classificacao)

    const normalizedObject: IDemanda = {
      classificacao: classificacaoIdArray,
      demandante: formObject?.demandante?.value,
      equipe: equipeIdArray,
      publico_alvo: publicoAlvoIdArray,
      subsecretaria: formObject?.subsecretaria?.value,
      responsavel: responsavelIdArray,
      justificativa: formObject?.justificativa,
      objetivo: formObject?.objetivo,
      nome: formObject?.nome,
      duracao: {
        inicio: selectedDates[0].getTime(),
        fim: selectedDates[1].getTime()
      }
    }

    return normalizedObject
  }

  const handleSaveEditDemanda = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedDemandaObject = normalizeDemandaData(formObject)
      setIsEditMode(false)
      await editDemanda(normalizedDemandaObject, demanda?.id)
      refreshDemanda()
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleSaveEditDemanda(formObject), DEMANDA_EDIT_TOAST_MESSAGE)
  }

  const handleCreateClassificacao = async (value: string): Promise<void> => {
    try {
      setCreateClassificacaoLoading(true)
      const classificacaoToCreate: IClassificacao = { nome: value }
      const response = await insertClassificacao(classificacaoToCreate)
      setValue('classificacao', [
        ...currentFormObject.classificacao,
        { label: value, value: response.id_classificacao }
      ])

      setCreateClassificacaoLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const normalizeTarefasDataforCreate = (): ITarefasDto => {
    if (tempTarefas) {
      const newTarefas = tempTarefas.map((tarefa) => ({
        ...tarefa,
        responsavel: returnNumberArrayFromIBasicInfoArray(tarefa.responsavel as IBasicInfo[])
      }))
      const tarefasDto: ITarefasDto = {
        tarefas: newTarefas
      }
      console.log('normalizeTarefasDataforCreate')

      return tarefasDto
    }
  }

  const handleRequestApproval = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedDemandaObject = normalizeDemandaData(formObject)
      const normalizedTarefasObject = normalizeTarefasDataforCreate()
      const response = await insertDemanda(normalizedDemandaObject)
      await insertTarefasOnDemanda(normalizedTarefasObject, response.id_demanda)

      navigate('/demandas/')
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickCreateDemanda = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleRequestApproval(formObject), DEMANDA_CREATE_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (demanda) {
      setIsEditMode(false)

      setValue('nome', demanda.nome)
      setValue('objetivo', demanda.objetivo)
      setValue('justificativa', demanda.justificativa)
      setSelectedDates([
        new Date(addThreeDigits(demanda.duracao?.inicio)),
        new Date(addThreeDigits(demanda.duracao?.fim))
      ])
      setValue('publico_alvo', returnNumberOptionArrayFromBasicInfoArray(demanda.publico_alvo as IBasicInfo[]))
      setValue('subsecretaria', returnOptionFromBasicInfo(demanda.subsecretaria as IBasicInfo))
      setValue('classificacao', returnNumberOptionArrayFromBasicInfoArray(demanda.classificacao as IBasicInfo[]))
      setValue('demandante', returnOptionFromBasicInfo(demanda.demandante as IBasicInfo))
      setValue('responsavel', returnNumberOptionArrayFromBasicInfoArray(demanda.responsavel as IBasicInfo[]))
      setValue('equipe', returnNumberOptionArrayFromBasicInfoArray(demanda.equipe as IBasicInfo[]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demanda])

  useEffect(() => {
    if (!demanda) {
      setIsEditMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demanda])

  if (demandaLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      <DemandEditorContainer>
        <div className="demand-editor-header">
          {demanda?.nome ?? 'Nova demanda'}
          {demanda ? (
            <>
              {isEditMode ? (
                <div className="edit-button-row">
                  <GenericButton onClick={handleSubmit(handleClickSubmitEdit)} isLoading={buttonLoading}>
                    Salvar
                  </GenericButton>
                  <GenericButton variant={'outline'} onClick={handleCancelEdit} color={'#242731'}>
                    Cancelar
                  </GenericButton>
                </div>
              ) : (
                <>
                  <GenericButton onClick={handleSetIsEditMode}>Editar</GenericButton>
                </>
              )}
            </>
          ) : (
            <GenericButton
              colorScheme="orange"
              onClick={handleSubmit(handleClickCreateDemanda)}
              isDisabled={!isFormComplete}
              isLoading={buttonLoading}
            >
              Criar demanda
            </GenericButton>
          )}
        </div>
        <div className="demand-editor-body">
          <div className="body-basic-data">
            <LargeCardInfo borderRadius={10}>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                DADOS BÁSICOS DA DEMANDA
              </CardHeader>

              <div className="large-card-info-body">
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Nome da demanda</label>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => <Input variant="flushed" isDisabled={!isEditMode} {...field} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Objetivo</label>
                    <Controller
                      name="objetivo"
                      control={control}
                      render={({ field }) => <Input variant="flushed" isDisabled={!isEditMode} {...field} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Subsecretaria Responsável</label>
                    <Controller
                      name="subsecretaria"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          options={subsecretarias?.map((subsecretaria) => ({
                            label: subsecretaria.nome,
                            value: subsecretaria.id
                          }))}
                          isDisabled={!isEditMode}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="label-input-bundle">
                    <label>Classificação</label>
                    <Controller
                      name="classificacao"
                      control={control}
                      render={({ field }) => (
                        <ReactSelectCreatable
                          isMulti
                          onCreateOption={handleCreateClassificacao}
                          isDisabled={createClassificacaoLoading || !isEditMode}
                          options={classificacao?.map((classificacao) => ({
                            label: classificacao.nome,
                            value: classificacao.id
                          }))}
                          placeholder={'Selecione ou crie...'}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Justificativa</label>
                    <Controller
                      name="justificativa"
                      control={control}
                      render={({ field }) => <Input variant="flushed" isDisabled={!isEditMode} {...field} />}
                    />
                  </div>

                  <div className="label-info-bundle">
                    <label>Prazo</label>
                    <DuracaoPicker
                      selectedDates={selectedDates}
                      onDateChange={handleDateChange}
                      disabled={!isEditMode}
                    />
                  </div>

                  <div className="label-input-bundle">
                    <label>Público Alvo</label>
                    <Controller
                      name="publico_alvo"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          isMulti
                          classNamePrefix="select"
                          className="basic-multi-select"
                          options={publicoAlvo?.map((publicoAlvo) => ({
                            label: publicoAlvo.nome,
                            value: publicoAlvo.id
                          }))}
                          isDisabled={!isEditMode}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </LargeCardInfo>
            <SmallFormCard>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                EQUIPE
              </CardHeader>
              <div className="small-card-form">
                <div className="label-input-bundle">
                  <label>Demandante</label>
                  <Controller
                    name="demandante"
                    control={control}
                    render={({ field }) => (
                      <ReactSelectCreatable
                        isMulti
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue ? 'Digite para criar demandante.' : 'Nenhuma opção encontrada.'
                        }
                        isDisabled={!isEditMode}
                        placeholder={'Digitar...'}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="label-input-bundle">
                  <label>Responsável</label>
                  <Controller
                    name="responsavel"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({
                          label: user.nome,
                          value: user.id
                        }))}
                        isDisabled={!isEditMode}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="label-input-bundle">
                  <label>Equipe envolvida</label>
                  <Controller
                    name="equipe"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({
                          label: user.nome,
                          value: user.id
                        }))}
                        isDisabled={!isEditMode}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </SmallFormCard>
          </div>
          <div className="tarefas-list-container">
            <TarefaList
              setShowNovaTarefaModal={setShowNovaTarefaModal}
              setTarefaModalData={setTarefaModalData}
              tarefasLoading={tarefasLoading}
              tarefas={tarefas}
              tempTarefas={tempTarefas}
            />
            <GenericTable<IDocumentos>
              title="DOCUMENTOS"
              columns={documentoColumns}
              data={documentos}
              isLoading={documentosLoading}
              onAddClick={handleShowNovoDocumentoModal}
              onRowClick={(documento) => {
                setDocumentoModalData(documento)
                console.log(documento)
                handleShowNovoDocumentoModal()
              }}
              noDataType="documentos"
            />
          </div>
          {!!params.id && <CommentChat comments={demanda?.comentarios} type="DEMANDA" entityId={demanda?.id} />}
        </div>
      </DemandEditorContainer>
      <ModalNovaTarefa
        projetoId={demanda?.id}
        setShowNovaTarefaModal={setShowNovaTarefaModal}
        setTarefaModalData={setTarefaModalData}
        showNovaTarefaModal={showNovaTarefaModal}
        tarefaModalData={tarefaModalData}
        refreshTarefas={refreshTarefas}
        setTempTarefas={setTempTarefas}
        tempTarefas={tempTarefas}
        isNewProjectScreen={!demanda}
      />
      <ModalNovoDocumento
        entityId={demanda?.id}
        refreshDocumentos={refreshDocumentos}
        setDocumentoModalData={setDocumentoModalData}
        setShowNovoDocumentoModal={setShowNovoDocumentoModal}
        showNovoDocumentoModal={showNovoDocumentoModal}
        documentoModalData={documentoModalData}
        isNewEntityScreen={!demanda}
        setTempDocumentos={setTempDocumentos}
        type="DEMANDA"
      />
    </>
  )
}
