import { ISubtarefa, ITarefa } from 'sigap/types/tarefas'
import { CONTRATOS, DEMANDAS, PROJETOS, TAREFAS, USER, api, cancelToken } from 'sigap/utils/constants'

export interface ITarefasDto {
  tarefas: ITarefa[]
}

export async function listTarefas(): Promise<ITarefa[]> {
  const { data: response } = await api.get(`/${TAREFAS}`)

  return response
}

export async function getTarefa(tarefaId: number): Promise<ITarefa> {
  const { data: response } = await api.get(`/${TAREFAS}/${tarefaId}`)

  return response
}

export async function editTarefasOnProject(tarefas: ITarefasDto, projectId: number): Promise<ITarefa> {
  const { data: response } = await api.patch(`/${TAREFAS}/${PROJETOS}/${projectId}`, tarefas)

  return response
}

export async function insertTarefasOnProject(tarefas: ITarefasDto, projectId: number): Promise<ITarefa> {
  const { data: response } = await api.post(`/${TAREFAS}/${PROJETOS}/${projectId}`, tarefas)

  return response
}

export async function insertTarefasOnDemanda(tarefas: ITarefasDto, demandaId: number): Promise<ITarefa> {
  const { data: response } = await api.post(`/${TAREFAS}/${DEMANDAS}/${demandaId}`, tarefas)

  return response
}
export async function insertTarefasOnContrato(tarefas: ITarefasDto, contratoId: number): Promise<ITarefa> {
  const { data: response } = await api.post(`/${TAREFAS}/${CONTRATOS}/${contratoId}`, tarefas)

  return response
}

export async function getTarefasByProject(projectId: number): Promise<ITarefa[]> {
  const { data: response } = await api.get(`/${TAREFAS}/${PROJETOS}/${projectId}`)

  return response
}

export async function getTarefasByUser(userId: number): Promise<ITarefa[]> {
  const { data: response } = await api.get(`/${TAREFAS}/${USER}/${userId}`, { cancelToken: cancelToken.token })

  return response
}

export async function getTarefasByDemanda(demandaId: number): Promise<ITarefa[]> {
  const { data: response } = await api.get(`/${TAREFAS}/${DEMANDAS}/${demandaId}`)

  return response
}

export async function getTarefasByContrato(contratoId: number): Promise<ITarefa[]> {
  const { data: response } = await api.get(`/${TAREFAS}/${CONTRATOS}/${contratoId}`)

  return response
}

export async function editTarefa(tarefa: Partial<ITarefa>, tarefaId: number): Promise<ITarefa> {
  const { data: response } = await api.patch(`/${TAREFAS}/${tarefaId}`, tarefa)

  return response
}

export async function insertCommentOnTarefa(comment: IComment, tarefaId: number): Promise<{ id_projeto: number }> {
  const { data: response } = await api.post(`/${TAREFAS}/${tarefaId}/comentarios`, comment)

  return response
}

export async function getCommentsOnTarefa(tarefaId: number): Promise<IComment[]> {
  const { data: response } = await api.get(`/${TAREFAS}/${tarefaId}/comentarios`)

  return response
}

export async function insertSubtarefa(subtarefa: ISubtarefa, tarefaId: number): Promise<{ id_projeto: number }> {
  const { data: response } = await api.post(`/${TAREFAS}/${tarefaId}/subtarefa`, subtarefa)

  return response
}
