import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelectCreatable from 'react-select/creatable'

import { CardHeader, Input, Textarea, useToast } from '@chakra-ui/react'
import { useRequest } from 'ahooks'

import { editContrato, getContrato, insertContrato } from 'sigap/services/contracts'
import { getDocumentosByContrato } from 'sigap/services/documentos'
import { IProdutosDto, insertProdutoOnContract, listProdutosByContrato } from 'sigap/services/produtos'
import { listSubsecretarias } from 'sigap/services/subsecretaria'
import { ITarefasDto, getTarefasByContrato, insertTarefasOnContrato } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IContrato, IProduto } from 'sigap/types/contracts'
import { ITarefa } from 'sigap/types/tarefas'
import { documentoColumns } from 'sigap/utils/constants/columns'
import { CONTRATO_CREATE_TOAST_MESSAGE, CONTRATO_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericTable } from 'sigap/utils/generic'
import { LargeCardInfo } from 'sigap/utils/generic/CardInfo/CardInfo'
import { CommentChat } from 'sigap/utils/generic/CommentChat/CommentChat'
import { DuracaoPicker } from 'sigap/utils/generic/DuracaoPicker/DuracaoPicker'
import { SmallFormCard } from 'sigap/utils/generic/FormCardStyles/FormCardStyles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { LoadingScreen } from 'sigap/utils/generic/LoadingScreen/LoadingScreen'
import { NumberMaskInput } from 'sigap/utils/generic/MaskInput/MaskInput'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { formatCurrencyToNumber } from 'sigap/utils/methods/formatCurrencyToNumber'
import { returnNumberArrayFromIBasicInfoArray } from 'sigap/utils/methods/returnNumberArrayFromIBasicInfoArray'
import { returnNumberOptionArrayFromBasicInfoArray } from 'sigap/utils/methods/returnNumberOptionArrayFromBasicInfoArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'
import { returnOptionFromBasicInfo } from 'sigap/utils/methods/returnOptionFromBasicInfo'

import { ModalNovaTarefa } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/ModalNovaTarefa/ModalNovaTarefa'
import { ModalNovoDocumento } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/ModalNovoDocumento/ModalNovoDocumento'
import { TarefaList } from '../../Projects/ProjectEditor/ApprovedProjectToComplete/TarefaList/TarefaList'
import { ModalNovoProduto } from '../NewContract/ModalProduto/ModalNovoProduto'
import { ProdutoList } from './ProdutoList/ProdutoList'
import { ContractEditorContainer } from './styles'

interface IFormData {
  nome: string
  objetivo: string
  subsecretaria: Option<number>
  valor_previsto: string
  valor_contrato: string
  parcelas: string
  fiscalizadores: Option<number>[]
  gestora: Option<number>[]
  celebrado: Option<number>
  equipe: Option<number>[]
  orcamento: string
  numero: string
  numero_sei: string
}

interface IDocumentoFormData {
  nome: string
  link: string
}

const formDefaultValues: IFormData = {
  nome: '',
  objetivo: '',
  fiscalizadores: undefined,
  subsecretaria: undefined,
  valor_previsto: '',
  valor_contrato: '',
  parcelas: '',
  celebrado: undefined,
  gestora: undefined,
  equipe: undefined,
  orcamento: '',
  numero: '',
  numero_sei: ''
}

export const ContractEditor: React.FC = () => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showNovaTarefaModal, setShowNovaTarefaModal] = useState<boolean>()
  const [tarefaModalData, setTarefaModalData] = useState<ITarefa>()
  const [tempTarefas, setTempTarefas] = useState<ITarefa[]>([])
  const [showNovoProdutoModal, setShowNovoProdutoModal] = useState<boolean>(false)
  const [produtoModalData, setProdutoModalData] = useState<IProduto>()
  const [tempProdutos, setTempProdutos] = useState<IProduto[]>([])
  const [showNovoDocumentoModal, setShowNovoDocumentoModal] = useState<boolean>(false)
  const [documentoModalData, setDocumentoModalData] = useState<IDocumentos>()
  const [tempDocumentos, setTempDocumentos] = useState<IDocumentos[]>()

  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const params = useParams()

  const {
    data: subsecretarias,
    loading: subsecretariaLoading,
    error: subsecretariaError
  } = useRequest(listSubsecretarias)

  const { data: users, loading: usersLoading, error: usersError } = useRequest(listUsers)

  const {
    data: documentos,
    refreshAsync: refreshDocumentos,
    loading: documentosLoading
  } = useRequest(getDocumentosByContrato, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })
  const {
    data: produtos,
    loading: produtosLoading,
    error: produtosError,
    refreshAsync: refreshProdutos
  } = useRequest(listProdutosByContrato, { defaultParams: [Number(params.id)], ready: !!params.id })

  const {
    data: tarefas,
    refreshAsync: refreshTarefas,
    loading: tarefasLoading
  } = useRequest(getTarefasByContrato, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })
  const {
    data: contrato,
    refreshAsync: refreshContrato,
    loading: contratoLoading
  } = useRequest(getContrato, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const toast = useToast()
  const navigate = useNavigate()

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>({
    mode: 'all',
    defaultValues: formDefaultValues
  })
  const currentFormData = watch()

  const isFormComplete = useMemo(
    () =>
      currentFormData.nome?.length > 0 &&
      currentFormData.numero?.length > 0 &&
      currentFormData.numero_sei?.length > 0 &&
      currentFormData.objetivo?.length > 0 &&
      currentFormData.valor_previsto?.length > 0 &&
      currentFormData.fiscalizadores != null &&
      currentFormData.subsecretaria != null &&
      currentFormData.gestora != null &&
      currentFormData.equipe != null &&
      tempProdutos.length > 0 &&
      tempTarefas.length > 0,
    [currentFormData, tempProdutos, tempTarefas]
  )

  const handleDateChange = (dates: Date[]): void => {
    setSelectedDates(dates)
  }

  const handleSetIsEditMode = (): void => {
    setIsEditMode(true)
  }

  const handleCancelEdit = (): void => {
    refreshContrato()
    setIsEditMode(false)
  }

  const handleShowNovoDocumentoModal = (): void => {
    setShowNovoDocumentoModal(true)
  }

  const normalizeContatoData = (formObject: IFormData): IContrato => {
    const fiscalizadoresIdArray = returnNumberValueArrayFromMultiSelect(formObject.fiscalizadores)
    const gestoresIdArray = returnNumberValueArrayFromMultiSelect(formObject.gestora)
    const equipeIdArray = returnNumberValueArrayFromMultiSelect(formObject.equipe)

    const normalizedContrato: IContrato = {
      nome: formObject?.nome,
      objetivo: formObject?.objetivo,
      duracao: {
        inicio: selectedDates[0]?.getTime(),
        fim: selectedDates[1]?.getTime()
      },
      subsecretaria: formObject?.subsecretaria?.value,
      fiscalizadores: fiscalizadoresIdArray,
      gestores: gestoresIdArray,
      equipe: equipeIdArray,
      valor: formatCurrencyToNumber(formObject?.valor_previsto)
    }

    return normalizedContrato
  }

  const handleSaveEditContrato = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedContratoObject = normalizeContatoData(formObject)
      setIsEditMode(false)
      await editContrato(normalizedContratoObject, contrato?.id)
      refreshContrato()
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitEdit = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleSaveEditContrato(formObject), CONTRATO_EDIT_TOAST_MESSAGE)
  }

  const normalizeTarefasDataForCreate = (): ITarefasDto => {
    if (tempTarefas) {
      const newTarefas = tempTarefas.map((tarefa) => ({
        ...tarefa,
        responsavel: returnNumberArrayFromIBasicInfoArray(tarefa.responsavel as IBasicInfo[])
      }))
      const tarefasDto: ITarefasDto = {
        tarefas: newTarefas
      }
      console.log('normalizeTarefasDataforCreate')

      return tarefasDto
    }
  }

  const normalizeProdutosDataForCreate = (): IProdutosDto => {
    if (tempProdutos) {
      const newProdutos = tempProdutos.map((produto) => ({
        ...produto,
        responsavel: (produto.responsavel as IBasicInfo).id
      }))
      const produtosDto: IProdutosDto = {
        produtos: newProdutos
      }

      return produtosDto
    }
  }

  const handleCreateContrato = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedContratoObject = normalizeContatoData(formObject)
      const normalizedTarefasObject = normalizeTarefasDataForCreate()
      const normalizedProdutoObject = normalizeProdutosDataForCreate()
      const response = await insertContrato(normalizedContratoObject)
      await insertTarefasOnContrato(normalizedTarefasObject, response.id_contrato)
      await insertProdutoOnContract(normalizedProdutoObject, response.id_contrato)

      navigate('/contratos/')
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickCreateContrato = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleCreateContrato(formObject), CONTRATO_CREATE_TOAST_MESSAGE)
  }

  useEffect(() => {
    if (contrato) {
      setIsEditMode(false)
      setValue('nome', contrato.nome)
      setValue('objetivo', contrato.objetivo)
      setValue('numero', contrato.numero)
      setValue('numero_sei', contrato.numero_sei)

      setSelectedDates([
        new Date(addThreeDigits(contrato.duracao?.inicio)),
        new Date(addThreeDigits(contrato.duracao?.fim))
      ])

      setValue('subsecretaria', returnOptionFromBasicInfo(contrato.subsecretaria as IBasicInfo))
      setValue('fiscalizadores', returnNumberOptionArrayFromBasicInfoArray(contrato.fiscalizadores as IBasicInfo[]))
      setValue('gestora', returnNumberOptionArrayFromBasicInfoArray(contrato.gestores as IBasicInfo[]))
      setValue('equipe', returnNumberOptionArrayFromBasicInfoArray(contrato.equipe as IBasicInfo[]))
      setValue(
        'valor_previsto',
        contrato.valor?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      )
      // setValue('celebrado', returnOptionFromBasicInfo(contrato.celebrado as IBasicInfo))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrato])

  useEffect(() => {
    if (!contrato) {
      setIsEditMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrato])

  if (contratoLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      <ContractEditorContainer>
        <div className="contract-editor-header">
          {contrato?.nome ?? 'Novo contrato'}
          {contrato ? (
            <>
              {isEditMode ? (
                <div className="edit-button-row">
                  <GenericButton
                    onClick={handleSubmit(handleClickSubmitEdit)}
                    isLoading={buttonLoading}
                    colorScheme="green"
                  >
                    Salvar
                  </GenericButton>
                  <GenericButton onClick={handleCancelEdit} variant={'outline'} color={'#242731'}>
                    Cancelar
                  </GenericButton>
                </div>
              ) : (
                <>
                  <GenericButton variant={'outline'} onClick={handleSetIsEditMode}>
                    Editar
                  </GenericButton>
                </>
              )}
            </>
          ) : (
            <GenericButton
              colorScheme="orange"
              onClick={handleSubmit(handleClickCreateContrato)}
              isDisabled={!isFormComplete}
              isLoading={buttonLoading}
            >
              Criar contrato
            </GenericButton>
          )}
        </div>
        <div className="contrato-editor-body">
          <div className="contrato-body-basic-data">
            <LargeCardInfo borderRadius={10}>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                DADOS BÁSICOS DO CONTRATO
              </CardHeader>

              <div className="large-card-info-body">
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Número do contrato</label>
                    <Controller
                      name="numero"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Número SEI</label>
                    <Controller
                      name="numero_sei"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Nome do contrato</label>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => <Input variant="flushed" {...field} isDisabled={!isEditMode} />}
                    />
                  </div>
                  <div className="label-info-bundle">
                    <label>Subsecretaria Responsável</label>
                    <Controller
                      name="subsecretaria"
                      control={control}
                      render={({ field }) => (
                        <GenericSelect
                          options={subsecretarias?.map((subsecretaria) => ({
                            label: subsecretaria.nome,
                            value: subsecretaria.id
                          }))}
                          isDisabled={!isEditMode}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="large-card-column">
                  <div className="label-info-bundle">
                    <label>Objeto</label>
                    <Controller
                      name="objetivo"
                      control={control}
                      render={({ field }) => (
                        <Textarea {...field} isDisabled={!isEditMode} style={{ height: '280px' }} />
                      )}
                    />
                  </div>

                  <div className="label-info-bundle">
                    <label>Prazo</label>
                    <DuracaoPicker
                      selectedDates={selectedDates}
                      onDateChange={handleDateChange}
                      disabled={!isEditMode}
                    />
                  </div>
                </div>
                <div className="large-card-column">
                  <div className="label-input-bundle">
                    <label>Valor total previsto do contrato</label>
                    <Controller
                      name="valor_previsto"
                      control={control}
                      render={({ field }) => (
                        <NumberMaskInput
                          variant="flushed"
                          format="currency"
                          currency="BRL"
                          isDisabled={!isEditMode}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="label-input-bundle">
                    <label>Valor do contrato</label>
                    <Controller
                      name="valor_contrato"
                      control={control}
                      render={({ field }) => (
                        <NumberMaskInput variant="flushed" format="currency" currency="BRL" isDisabled {...field} />
                      )}
                    />
                  </div>
                </div>
              </div>
            </LargeCardInfo>

            <SmallFormCard borderRadius={10}>
              <CardHeader
                backgroundColor={'#004874'}
                borderRadius={10}
                fontSize={16}
                padding={'unset'}
                paddingLeft={'12px'}
                color={'#fff'}
                fontFamily={'Poppins'}
                fontStyle={'normal'}
                fontWeight={500}
                lineHeight={'36px'}
              >
                EQUIPE
              </CardHeader>
              <div className="small-card-form">
                <div className="label-input-bundle">
                  <label>Fiscalizadores</label>
                  <Controller
                    name="fiscalizadores"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({
                          label: user.nome,
                          value: user.id
                        }))}
                        isDisabled={!isEditMode}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="label-input-bundle">
                  <label>Celebrado com</label>
                  <Controller
                    name="celebrado"
                    control={control}
                    render={({ field }) => (
                      <ReactSelectCreatable
                        isMulti
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue ? 'Digite para criar celebrado.' : 'Nenhuma opção encontrada.'
                        }
                        isDisabled={!isEditMode}
                        placeholder={'Digitar...'}
                        {...field}
                      />
                    )}
                  />
                </div>

                <div className="label-input-bundle">
                  <label>Gestores do contrato</label>
                  <Controller
                    name="gestora"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({
                          label: user.nome,
                          value: user.id
                        }))}
                        isDisabled={!isEditMode}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="label-input-bundle">
                  <label>Equipe do contrato</label>
                  <Controller
                    name="equipe"
                    control={control}
                    render={({ field }) => (
                      <GenericSelect
                        isMulti
                        options={users?.map((user) => ({
                          label: user.nome,
                          value: user.id
                        }))}
                        isDisabled={!isEditMode}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </SmallFormCard>
          </div>
          <div className="contrato-editor-list-container">
            <TarefaList
              tempTarefas={tempTarefas}
              tarefas={tarefas}
              setShowNovaTarefaModal={setShowNovaTarefaModal}
              setTarefaModalData={setTarefaModalData}
              tarefasLoading={tarefasLoading}
            />
            <ProdutoList
              produtos={produtos}
              produtosLoading={produtosLoading}
              setProdutoModalData={setProdutoModalData}
              setShowNovoProdutoModal={setShowNovoProdutoModal}
              tempProdutos={tempProdutos}
            />
            <GenericTable<IDocumentos>
              title="DOCUMENTOS"
              columns={documentoColumns}
              data={documentos}
              isLoading={documentosLoading}
              onAddClick={handleShowNovoDocumentoModal}
              onRowClick={(documento) => {
                setDocumentoModalData(documento)
                handleShowNovoDocumentoModal()
              }}
              noDataType="documentos"
            />
          </div>
          {!!params.id && <CommentChat comments={contrato?.comentarios} type="CONTRATO" entityId={contrato?.id} />}
        </div>
      </ContractEditorContainer>

      <ModalNovaTarefa
        projetoId={contrato?.id}
        setShowNovaTarefaModal={setShowNovaTarefaModal}
        setTarefaModalData={setTarefaModalData}
        showNovaTarefaModal={showNovaTarefaModal}
        tarefaModalData={tarefaModalData}
        refreshTarefas={refreshTarefas}
        setTempTarefas={setTempTarefas}
        tempTarefas={tempTarefas}
        isNewProjectScreen={!contrato}
      />
      <ModalNovoProduto
        contratoId={contrato?.id}
        setShowNovoProdutoModal={setShowNovoProdutoModal}
        showProdutoModal={showNovoProdutoModal}
        users={users}
        setProdutoModalData={setProdutoModalData}
        produtoModalData={produtoModalData}
        refreshProdutos={refreshProdutos}
        setTempProdutos={setTempProdutos}
        tempProdutos={tempProdutos}
        isNewContratoScreen={!contrato}
      />
      <ModalNovoDocumento
        entityId={contrato?.id}
        refreshDocumentos={refreshDocumentos}
        setDocumentoModalData={setDocumentoModalData}
        setShowNovoDocumentoModal={setShowNovoDocumentoModal}
        showNovoDocumentoModal={showNovoDocumentoModal}
        documentoModalData={documentoModalData}
        isNewEntityScreen={!contrato}
        setTempDocumentos={setTempDocumentos}
        type="CONTRATO"
      />
    </>
  )
}
