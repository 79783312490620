import React, { useContext, useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Controller, useForm } from 'react-hook-form'

import { Button, Checkbox, Input } from '@chakra-ui/react'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { IUpdateUserInfo, updateUserInfo } from 'sigap/services/users'

import { PerfilContainer } from './styles'

interface IFormData {
  nome: string
  email: string
  senha: string
  email_notification: boolean
  perfil: string
  subsecretaria: string
  area: string
}

export const Perfil: React.FC = () => {
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()

  const authContext = useContext(AuthenticationContext)
  const currentFormData = watch()

  const handleUpdateUser = async (formData: IFormData): Promise<void> => {
    setIsLoadingUpdate(true)
    const userInfo: IUpdateUserInfo = {
      email_notification: formData.email_notification,
      is_active: true,
      nome: formData.nome
    }
    await updateUserInfo(userInfo, authContext?.currentUser?.id)
  }

  useEffect(() => {
    if (authContext?.currentUser) {
      setValue('nome', authContext?.currentUser?.nome)
      setValue('email', authContext?.currentUser?.email)
      setValue('perfil', authContext?.currentUser?.perfil)
      setValue('subsecretaria', authContext?.currentUser?.subsecretaria)
      setValue('area', authContext?.currentUser?.area)
      setValue('email_notification', authContext?.currentUser?.email_notification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext?.currentUser])

  return (
    <PerfilContainer>
      <div className="perfil-header">
        <Avatar name={authContext?.currentUser?.nome} size="50" round={true} color="#4CAF50" fgColor="#FFFFFF" />
        &ensp;&ensp;Perfil do Usuário
      </div>
      <div className="perfil-body">
        <div className="perfil-body-form-column">
          <div className="label-input-bundle">
            <label>Nome do Usuário</label>
            <Controller name="nome" control={control} render={({ field }) => <Input variant="flushed" {...field} />} />
          </div>
          <div className="label-input-bundle">
            <label>E-mail</label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => <Input variant="flushed" {...field} isDisabled />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Perfil</label>
            <Controller
              name="perfil"
              control={control}
              render={({ field }) => <Input variant="flushed" {...field} isDisabled />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Subsecretaria</label>
            <Controller
              name="subsecretaria"
              control={control}
              render={({ field }) => <Input variant="flushed" {...field} isDisabled />}
            />
          </div>
          <div className="label-input-bundle">
            <label>Area</label>
            <Controller
              name="area"
              control={control}
              render={({ field }) => <Input variant="flushed" {...field} isDisabled />}
            />
          </div>
          <div className="label-input-bundle">
            <Controller
              name="email_notification"
              control={control}
              render={({ field }) => (
                <Checkbox
                  borderColor={'#979797'}
                  {...field}
                  value={field.value as unknown as string}
                  isChecked={currentFormData?.email_notification}
                >
                  Receber notificações?
                </Checkbox>
              )}
            />
          </div>
          <Button
            onClick={handleSubmit(handleUpdateUser)}
            backgroundColor={'#001b37'}
            color={'white'}
            isLoading={isLoadingUpdate}
          >
            Salvar
          </Button>
        </div>
      </div>
    </PerfilContainer>
  )
}
