import React, { useEffect, useState } from 'react'

import { Input } from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import moment from 'moment'

interface IProps {
  selectedDate: Date
  onDateChange: (dates: Date) => void
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>
}

export const SingleDatePicker: React.FC<IProps> = ({ selectedDate, onDateChange, setSelectedDate }) => {
  const [inputValue, setInputValue] = useState<string>('')

  const handleDateInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setInputValue(value)
  }

  useEffect(() => {
    if (selectedDate) {
      setInputValue(selectedDate.toLocaleDateString())
    }
  }, [selectedDate])

  useEffect(() => {
    const date = new Date(inputValue)
    if (inputValue.length === 10 && !isNaN(date.getTime())) {
      setSelectedDate(new Date(moment(date).format('L')))
      console.log('input dentro func', inputValue.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  console.log(selectedDate)

  return (
    <SingleDatepicker
      date={selectedDate}
      onDateChange={onDateChange}
      propsConfigs={{
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            _hover: {
              backgroundColor: '#91d8f7'
            }
          },
          isInRangeBtnProps: { backgroundColor: '#24aae1' },
          todayBtnProps: { backgroundColor: '#91d8f7' },
          selectedBtnProps: { backgroundColor: '#24aae1' }
        },
        inputProps: {
          as: Input,
          value: inputValue,
          onChange: handleDateInputChange,
          placeholder: 'Selecione ou digite uma data'
        }
      }}
    />
  )
}
