import React, { useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { BsPlusLg } from 'react-icons/bs'

import { CircularProgress, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useRequest } from 'ahooks'
import { set } from 'date-fns'

import { listUsers } from 'sigap/services/users'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { NoDataComponent } from 'sigap/utils/generic/NoDataComponent/NoDataComponent'

import { AdminContainer } from './styles'
import { UserList } from './UserList/UserList'
import { UserModal } from './UserModal/UserModal'

const columns: TableColumn<IUser>[] = [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    grow: 10
  },
  {
    name: 'NOME',
    selector: (row) => row.nome,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'EMAIL',
    selector: (row) => row.email,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'PERFIL',
    selector: (row) => row.perfil,
    sortable: true,
    grow: 40,
    wrap: true
  },
  {
    name: 'ÁREA',
    selector: (row) => row.area,
    sortable: true,
    grow: 40,
    wrap: true
  }
]

export const Admin: React.FC = () => {
  const [showUserModal, setShowUserModal] = useState<boolean>(false)
  const [userModalData, setUserModalData] = useState<IUser>(null)

  const { data: users, loading: usersLoading } = useRequest(listUsers)

  const handleShowModal = (user?: IUser): void => {
    setShowUserModal(true)
    if (user != null) {
      setUserModalData(user)
    }
  }

  return (
    <AdminContainer>
      <Tabs>
        <TabList>
          <Tab>Usuários</Tab>
          {/* <Tab>Subsecretarias</Tab> */}
          {/* <Tab>ODS</Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserList />
          </TabPanel>
          {/* <TabPanel></TabPanel> */}
          {/* <TabPanel></TabPanel> */}
        </TabPanels>
      </Tabs>
    </AdminContainer>
  )
}
