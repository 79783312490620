import React, { useState } from 'react'

import { Button, Checkbox, Input } from '@chakra-ui/react'

import { ISubtarefa } from 'sigap/types/tarefas'

import { InputStackContainer } from './styles'

interface IProps {
  subtarefas: ISubtarefa[]
  handleEditSubtarefa?: () => Promise<void>
  handleAddSubtarefa: (subtarefa: ISubtarefa) => Promise<void>
}

export const InputStack: React.FC<IProps> = ({ subtarefas, handleAddSubtarefa, handleEditSubtarefa }) => {
  const [subtarefa, setSubtarefa] = useState<ISubtarefa>({ nome: '', concluida: false })

  return (
    <InputStackContainer>
      {subtarefas?.map((subtarefa, index) => {
        return (
          <div className="new-subtask-input" key={index}>
            <Checkbox
              borderColor={'#979797'}
              isChecked={subtarefa.concluida}
              onChange={(e) => console.log(e.target.checked)}
            />
            <Input value={subtarefa.nome} />
            <Button onClick={handleEditSubtarefa} isDisabled>
              Editar
            </Button>
          </div>
        )
      })}
      <div className="new-subtask-input">
        <Checkbox
          borderColor={'#979797'}
          isChecked={subtarefa?.concluida}
          onChange={(e) => setSubtarefa((old) => ({ nome: old.nome, concluida: e.target.checked }))}
        />
        <Input onChange={(e) => setSubtarefa((old) => ({ nome: e.target.value, concluida: old.concluida }))} />
        <Button onClick={() => handleAddSubtarefa(subtarefa)}>Adicionar subtarefa</Button>
      </div>
    </InputStackContainer>
  )
}
