import React, { useState } from 'react'
import Avatar from 'react-avatar'
import { Controller, useForm } from 'react-hook-form'

import { Button, Textarea } from '@chakra-ui/react'
import moment from 'moment'

import { getCommentsOnContrato, insertCommentOnContrato } from 'sigap/services/contracts'
import { getCommentsOnDemanda, insertCommentOnDemanda } from 'sigap/services/demandas'
import { getCommentsOnProduto, insertCommentOnProduto } from 'sigap/services/produtos'
import { getCommentsOnProject, insertCommentOnProject } from 'sigap/services/projects'
import { getCommentsOnTarefa, insertCommentOnTarefa } from 'sigap/services/tarefas'

import { CommentChatContainer } from './styles'

const formDefaultValues: IFormData = {
  comment: ''
}

interface IFormData {
  comment: string
}

interface IProps {
  comments: IComment[]
  entityId: number
  type: 'PROJETO' | 'CONTRATO' | 'DEMANDA' | 'TAREFA' | 'PRODUTO'
}

export const CommentChat: React.FC<IProps> = ({ comments, entityId, type }) => {
  const [usedComments, setUsedComments] = useState<IComment[]>(comments)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const { handleSubmit, control, setValue } = useForm<IFormData>({
    mode: 'all',
    defaultValues: formDefaultValues
  })

  const handleSendComment = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      if (type === 'PROJETO') {
        await insertCommentOnProject({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnProject(entityId)
        setUsedComments(newComments)
      }
      if (type === 'CONTRATO') {
        await insertCommentOnContrato({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnContrato(entityId)
        setUsedComments(newComments)
      }
      if (type === 'DEMANDA') {
        await insertCommentOnDemanda({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnDemanda(entityId)
        setUsedComments(newComments)
      }
      if (type === 'TAREFA') {
        await insertCommentOnTarefa({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnTarefa(entityId)
        setUsedComments(newComments)
      }
      if (type === 'PRODUTO') {
        await insertCommentOnProduto({ comentario: formObject.comment }, entityId)
        const newComments = await getCommentsOnProduto(entityId)
        setUsedComments(newComments)
      }

      setValue('comment', '')
    } catch (error) {
      console.log(error)
    } finally {
      setButtonLoading(false)
    }
  }

  return (
    <CommentChatContainer>
      <div className="comments-header">COMENTÁRIOS</div>
      <div className="comment-input-container">
        <Controller name="comment" control={control} render={({ field }) => <Textarea {...field} />} />
        <Button onClick={handleSubmit(handleSendComment)} isLoading={buttonLoading}>
          Enviar comentário
        </Button>
      </div>
      <div className="comment-list">
        {usedComments != null ? (
          usedComments
            ?.sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            })
            .map((comment, index) => {
              return (
                <div key={index} className="comment-card">
                  <div className="identification-row">
                    <Avatar name={comment.user_name} size="50" round />
                    <div className="name-time-column">
                      <div>{comment.user_name}</div>
                      <div className="date-time">
                        {moment(new Date(comment.created_at)).format('DD/MM/YYYY, HH:mm')}
                      </div>
                    </div>
                  </div>
                  <div className="text-row">{comment.comentario}</div>
                </div>
              )
            })
        ) : (
          <div>Não há comentários.</div>
        )}
      </div>
    </CommentChatContainer>
  )
}
